import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import Contact from '../../components/FormFields/Contact';
import Logo from '../../components/FormFields/Logo';
import Passenger from '../../components/FormFields/Passenger';
import Trip from '../../components/FormFields/Trip';
import MultiCity from '../../components/FormFields/MultiCity';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchOrders, fetchTickets } from '../../api';
import * as Yup from 'yup';
import Debug from '../../components/FormFields/Debug';
import ErrorFocus from '../../components/FormFields/ErrorFocus';
import { log, usePrevious } from '../../helpers';
import { FileReaderResult, Ticket } from '../../types';
import AttachmentUploader from '../../components/FormFields/AttachmentUploader';
import {
  trackTicketingForm,
  TrackingGenericInfoSerializable
} from '../../helpers/tracking';
import { AmazonXrayTraceId } from '../../helpers/response';
import { baseApiLambdaURL } from '../../config';
import FormHeader from '../../components/FormFields/FormHeader';
import { isEqual } from 'lodash';

type Props = {};

const requiredMessage = 'This field is required.';

const formSchema = Yup.object().shape({
  email: Yup.object({
    to: Yup.string().required(requiredMessage),
    cc: Yup.string(),
    bcc: Yup.string(),
    subject: Yup.string(),
    attachments: Yup.array().of(Yup.string())
  }),
  passenger: Yup.array().of(
    Yup.object({
      firstName: Yup.string().required(requiredMessage),
      lastName: Yup.string().required(requiredMessage),
      trips: Yup.array().of(
        Yup.object({
          ticketNumber: Yup.string().matches(
            /(\d{3})[-.](\d{10})$/,
            '###-##########'
          )
        })
      )
    })
  )
});

type SubmissionState =
  | undefined
  | 'should-submit-prompt'
  | 'submission-step-1'
  | 'submission-step-2'
  | 'submission-step-done';

const disabledButtonClasses = [
  'bg-gray-200',
  'hover:bg-gray-300',
  'text-white',
  'cursor-not-allowed'
];
const disabledButtonProps = {
  disabled: true,
  title:
    'ERROR: This ticket is not in a valid state! Please recreate this ticket as a new ticket to continue interacting with the ticket.'
};

const ETicketForm: React.FC<Props> = () => {
  const params = useParams<{ id: string }>();
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();
  const [submissionState, setSubmissionState] = useState<SubmissionState>();

  const ticketResult = useQuery('ticket', async () => {
    const token = await getAccessTokenSilently();
    const json = await fetchTickets(token).get.byOrder(params.id);
    return json;
  });
  const queryClient = useQueryClient();
  const ticket = ticketResult.data?.payload.ticket;

  const _alertFormChangesInvalid = (
    info: TrackingGenericInfoSerializable,
    ...args: any
  ) => {
    trackTicketingForm('form-changes', 'status-changed-invalid', info);
    alert(...args);
  };

  const previousTicket = usePrevious(ticket);
  useEffect(() => {
    if (!isEqual(ticket, previousTicket)) {
      if (
        ticket !== undefined &&
        previousTicket !== undefined &&
        ticket.isInvalid === true &&
        previousTicket.isInvalid === false
      ) {
        _alertFormChangesInvalid(
          { uid: '', info: '' },
          'One or more recent changes has rendered this ticket and order invalid! Please create a new ticket from scratch to continue.'
        );
      }
    }
  }, [ticket, previousTicket]);

  //top level email
  const [tripType, setTripType] = useState(ticket?.tripType);
  const [logo, setLogo] = useState(ticket?.logo);
  const [selectedFiles, setSelectedFiles] = useState<FileReaderResult[]>([]);

  const removeTicketQuery = useCallback(() => {
    queryClient.removeQueries('ticket');
    log('Unloaded ticket.');
  }, [queryClient]);

  useEffect(() => {
    return removeTicketQuery;
  }, [removeTicketQuery]);

  useEffect(() => {
    if (ticketResult.isFetched) {
      setTripType(ticket.tripType);
      setLogo(ticket.logo);
    }
    //eslint-disable-next-line
  }, [ticketResult.isFetched]);

  async function updateOrder(values: any) {
    if (user?.email) {
      const token = await getAccessTokenSilently();
      await fetchOrders(token).update(params.id, params.id, user.email, values);
      log('Updated order fields', values);
    } else {
      throw Error('User is missing, please authenticate');
    }
  }

  async function handleSubmit(values: Ticket) {
    setSubmissionState('submission-step-1');

    values.attachments = selectedFiles;
    trackTicketingForm('submit', 'invoked');
    const _alert = (info: TrackingGenericInfoSerializable, ...args: any) => {
      trackTicketingForm('submit', 'alerted', info);
      alert(...args);
    };
    const res = await fetch(`${baseApiLambdaURL}/eticket`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
      .then(
        (response: Response) =>
          trackTicketingForm('submit', 'resolved', response) as Response
      )
      .catch(
        (caught: Error) =>
          trackTicketingForm('submit', 'rejected', caught) as never
      )
      .then(async response => {
        const _tripType = values.tripType;
        let finalArrivalDate;

        const lastOnewayFlightIndex = values.outbound.flightInfo.length - 1;

        const lastReturnFlightIndex = values.return.flightInfo.length - 1;

        const lastMultiCityTripIndex = values.multiCity.length - 1;
        const finalFlightIndex =
          values.multiCity[lastMultiCityTripIndex].flightInfo.length - 1;
        const lastMultiCityFlight =
          values.multiCity[lastMultiCityTripIndex].flightInfo[finalFlightIndex];

        // Calculate final arrive date.
        switch (_tripType) {
          case 'oneway': {
            finalArrivalDate =
              values.outbound.flightInfo[lastOnewayFlightIndex].arriveDatetime;
            break;
          }
          case 'roundtrip': {
            finalArrivalDate =
              values.return.flightInfo[lastReturnFlightIndex].arriveDatetime;
            break;
          }
          case 'multicity': {
            finalArrivalDate = lastMultiCityFlight.arriveDatetime;
            break;
          }
          default:
            break;
        }

        if (response.ok) {
          trackTicketingForm('submit', '-ok');
          setSubmissionState('submission-step-2');
          try {
            trackTicketingForm('submit-update', 'invoked');
            await updateOrder({
              finalArrivalDate: `${finalArrivalDate}Z`,
              submitted: true,
              nSubmitted: ticket.nSubmitted + 1 || 1
            });
            trackTicketingForm('submit-update', 'resolved');
            setSubmissionState('submission-step-done');
            history.push('/eticket_submission_confirmation');
          } catch (error) {
            const xrayId = AmazonXrayTraceId(response);
            trackTicketingForm(
              'submit-update',
              'rejected',
              (error || {}) as Error
            );
            _alert(
              {
                uid: 'etkt200+update!ok',
                info: `${values._id || 'missing ticket id...'}[${xrayId}]`
              },
              [
                'Ticket was successfully submitted! However, we could not update our servers with the updated order details.',
                'This means the e-ticket order on our systems may not exactly match the real flight information or any future changes',
                'Please share the following with the development team for further analysis:',
                `    Ticket ID: ${values._id}`
              ].join('\n')
            );
            setSubmissionState(undefined);
          }
          return response.json();
        } else {
          trackTicketingForm('submit', '-!ok');

          const xrayId = AmazonXrayTraceId(response);
          _alert(
            {
              uid: 'etkt!200',
              info: `${response.status}[${xrayId}]`
            },
            [
              `Ticket did not successfully submit. Reason:`,
              `    ${response.status} (${response.statusText})`,
              `Please check the logs on CloudWatch for submit email failure.`,
              `X-Ray Trace: ${xrayId} (share this with the development team for further analysis)`
            ].join('\n')
          );

          setSubmissionState(undefined);
        }
      })
      .then(json => json);

    setSubmissionState(undefined);
    _alert(
      {
        uid: 'etkt!ok',
        info: `${res.e.code}|${res.e.message}`
      },
      `Ticket did not send. Reason:\n\t${res.e.code}: ${res.e.message}\nPlease check your form fields.`
    );
  }

  async function handlePreview(values: any) {
    const form = document.getElementById('main-form') as HTMLFormElement;
    const isValid = form.reportValidity();
    const errors: string[] = [];
    const _alert = (info: TrackingGenericInfoSerializable, ...args: any) => {
      trackTicketingForm('preview', 'alerted', info);
      alert(...args);
    };

    try {
      formSchema.validateSync(values);
    } catch (error) {
      errors.push(error as string);
    }

    if (isValid) {
      values.attachments = selectedFiles;
      trackTicketingForm('preview', 'invoked');
      await fetch(`${baseApiLambdaURL}/preview_eticket`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      })
        .then(
          (response: Response) =>
            trackTicketingForm('preview', 'resolved', response) as Response
        )
        .catch(
          (caught: Error) =>
            trackTicketingForm('preview', 'rejected', caught) as never
        )
        .then(response => {
          if (response.ok) {
            trackTicketingForm('preview', '-ok');
            if (errors.length > 0) {
              _alert(
                { uid: 'preview200wErrors', info: `[${errors.join(';')}]` },
                `Ticket has been submitted to the agent for preview with errors. ${errors.join(
                  ' '
                )}`
              );
            } else {
              alert('Ticket has been submitted to the agent for preview.');
            }
            return response.json();
          } else {
            const xrayId = AmazonXrayTraceId(response);
            trackTicketingForm('preview', '-!ok');
            _alert(
              { uid: 'preview!200', info: `${response.status}[${xrayId}]` },
              `Ticket did not send. Reason:\n    ${response.status}\nPlease check the logs on CloudWatch for preview email failure.\nTrace: ${xrayId} (share this with the development team for further analysis)`
            );
          }
        })
        .then(json => json);
    }
  }

  if (ticketResult.isLoading) return <div>...loading</div>;

  if (!ticket) return null;

  return (
    <Formik
      enableReinitialize
      initialValues={ticket}
      onSubmit={handleSubmit}
      validationSchema={formSchema}
    >
      {(props: FormikProps<any>) => (
        <div>
          <Debug jsons={[props.errors, props.values]} collapse />
          <div className="flex-grow mx-4 lg:mx-8">
            <div>
              {ticket.isInvalid && (
                <div>
                  <FormHeader
                    title="ERROR: This ticket is not in a valid state; and actions like Preview and Submit are disabled. Please create a new ticket to proceed!"
                    error={true}
                  />
                </div>
              )}
              <div>
                <AttachmentUploader
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                />
              </div>
              <Form className="" id="main-form">
                <Logo
                  updateOrder={updateOrder}
                  tripType={tripType}
                  setTripType={setTripType}
                  logo={logo}
                  setLogo={setLogo}
                  name="logo"
                  {...props}
                />
                <Contact updateOrder={updateOrder} {...props} />
                <Passenger
                  orderId={params.id}
                  updateOrder={updateOrder}
                  {...props}
                />
                {tripType !== 'multicity' && (
                  <Trip fieldGroup="outbound" {...props} />
                )}
                {tripType === 'roundtrip' && 'return' in ticket && (
                  <Trip fieldGroup="return" {...props} />
                )}
                {tripType === 'multicity' && <MultiCity {...props} />}
                <div className="flex w-full bg-gray-100 pb-8 space-x-4">
                  <button
                    className={[
                      'text-center',
                      'text-lg',
                      'p-3',
                      'w-full',
                      ...(ticket.isInvalid === false
                        ? [
                            'bg-blue-500',
                            'hover:bg-blue-400',
                            'text-blue-900',
                            'font-semibold',
                            'cursor-pointer'
                          ]
                        : disabledButtonClasses)
                    ].join(' ')}
                    type="button"
                    {...(ticket.isInvalid === true && disabledButtonProps)}
                    onClick={() => handlePreview(props.values)}
                  >
                    PREVIEW
                  </button>
                  <button
                    className={[
                      'text-center',
                      'text-lg',
                      'p-3',
                      'w-full',
                      ...(ticket.isInvalid === false
                        ? [
                            'bg-green-500',
                            'hover:bg-green-400',
                            'text-green-900',
                            'font-semibold',
                            'cursor-pointer'
                          ]
                        : disabledButtonClasses)
                    ].join(' ')}
                    type="button"
                    {...(ticket.isInvalid === true && disabledButtonProps)}
                    onClick={() => setSubmissionState('should-submit-prompt')}
                  >
                    SUBMIT
                  </button>
                  {submissionState !== undefined && (
                    <div
                      className="fixed z-40 w-full left-0 top-0"
                      {...(submissionState !== 'should-submit-prompt' && {
                        style: {
                          marginLeft: 0,
                          backgroundColor: 'rgba(80, 80, 80, 0.8)'
                        }
                      })}
                    >
                      <div className="flex  justify-center items-center h-screen">
                        {submissionState === 'should-submit-prompt' && (
                          <div className="bg-white shadow-md border rounded p-2 space-y-4">
                            <h2>
                              Are you sure you want to submit this ticket?
                            </h2>
                            <div className="flex space-x-2 justify-center">
                              <button
                                className="flex-grow rounded p-2 bg-blue-500 hover:bg-blue-400 text-blue-100"
                                type="submit"
                              >
                                SUBMIT
                              </button>
                              <button
                                className="flex-grow rounded p-2 bg-gray-500 hover:bg-gray-400 text-gray-100"
                                type="button"
                                onClick={() => setSubmissionState(undefined)}
                              >
                                CANCEL
                              </button>
                            </div>
                          </div>
                        )}
                        {[
                          'submission-step-1',
                          'submission-step-2',
                          'submission-step-done'
                        ].indexOf(submissionState) !== -1 && (
                          <div className="bg-white shadow-md border rounded p-2 space-y-4">
                            <h2>Submission in progress...</h2>
                            <progress
                              value={
                                {
                                  'should-submit-prompt': 0.0,
                                  'submission-step-1': 0.2,
                                  'submission-step-2': 0.6,
                                  'submission-step-done': 1.0
                                }[submissionState]
                              }
                              style={{ width: '100%' }}
                            />

                            <div
                              className={(submissionState !==
                              'submission-step-1'
                                ? ['text-gray-200', 'line-through']
                                : []
                              ).join(' ')}
                            >
                              <b>STEP 1 of 3:</b> Submitting order details,
                              confirmation emails, etc.
                            </div>
                            <div
                              className={[
                                ...(submissionState !== 'submission-step-2'
                                  ? ['text-gray-200']
                                  : []),
                                ...(submissionState === 'submission-step-done'
                                  ? ['line-through']
                                  : [])
                              ].join(' ')}
                            >
                              <b>STEP 2 of 3:</b> Updating order internally to
                              reflect submission
                            </div>
                            <div
                              className={(submissionState !==
                              'submission-step-done'
                                ? ['text-gray-200']
                                : []
                              ).join(' ')}
                            >
                              <b>STEP 3 of 3:</b> Done! Redirecting you back
                              home...
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <ErrorFocus />
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default ETicketForm;

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useQuery } from 'react-query';
import { TicketList } from './TicketList';
import { apiUrl } from '../../config';

export const AgentTicketContainer = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const ordersResult = useQuery('orders', async () => {
    const token = await getAccessTokenSilently();
    const json = await fetch(`${apiUrl}/api/agent/${user?.email}/orders`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => res.json())
      .catch(e => console.error(e));
    return json;
  });

  const orders = ordersResult.data?.payload.orders;
  if (ordersResult.isLoading) return null;

  return <TicketList tickets={orders} queryResult={ordersResult} />;
};

import React from 'react';
import { FileReaderResult } from '../../types';
import Attachment from './Attachment';
import FormHeader from './FormHeader';
import shortid from 'shortid';

interface LogoProps {
  selectedFiles: FileReaderResult[];
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileReaderResult[]>>;
}

const AttachmentUploader: React.FC<LogoProps> = ({
  selectedFiles,
  setSelectedFiles
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files?.length) {
      return;
    }

    const file = files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedFiles([
        { id: shortid.generate(), name: file.name, file: reader.result },
        ...selectedFiles
      ]);
    };
  };

  const handleRemove = (id: string) => {
    const newSelectedFiles = selectedFiles.filter(f => f.id !== id);
    setSelectedFiles(newSelectedFiles);
  };

  return (
    <div className="field-group-card">
      <FormHeader title="Attachment" />
      <div className="mx-6 mt-6">
        <div className="flex space-x-4 mb-6">
          <label htmlFor="attachment">Attachment</label>
          <input
            type="file"
            accept="application"
            onChange={e => handleChange(e)}
          />
        </div>
        <div className="flex space-x-2">
          {selectedFiles.length ? (
            selectedFiles.map(b => (
              <Attachment key={b.id} file={b} handleRemove={handleRemove} />
            ))
          ) : (
            <div className="p-3 border rounded flex items-center w-full">
              No attachments
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AttachmentUploader;

import { getIn } from 'formik';
import React from 'react';

interface TextFieldProps {
  label: string;
  required: boolean;
  type: string;
  name: string;
  readOnly?: boolean;
  errors?: any;
  value?: string;
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  required,
  type,
  errors,
  ...props
}) => {
  return (
    <div className="mt-2">
      <label>
        {label} <span className="text-red-500">{required ? '*' : ''}</span>
      </label>
      <div>
        <input
          className={`border rounded bg-gray-100 p-3 mt-2 w-full ${
            getIn(errors, props.name) ? 'border-red-400' : ''
          }`}
          autoComplete="none"
          type={type}
          required={required}
          {...props}
        />
      </div>
    </div>
  );
};

export default TextField;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import { fetchTickets } from '../../api';
import Debug from '../../components/FormFields/Debug';

const CompatibilityMode: React.FC = () => {
  const params = useParams<{ id: string }>();
  const { getAccessTokenSilently } = useAuth0();

  const ticketResult = useQuery('ticket', async () => {
    const token = await getAccessTokenSilently();
    const json = await fetchTickets(token).get.byOrder(params.id);
    return json;
  });
  const queryClient = useQueryClient();
  const ticket = ticketResult.data?.payload.ticket;

  useEffect(() => {
    return () => {
      queryClient.removeQueries('ticket');
    };
  }, [queryClient]);

  if (ticketResult.isLoading) return <div>...loading</div>;

  if (!ticket) return null;

  return <Debug jsons={[ticket]} />;
};

export default CompatibilityMode;

import React, { useEffect, useState } from 'react';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import TripFields from '../FormFields/TripFields';
import MultiCityFlightInfo from './MultiCityFlightInfo';
import RedCross from '../RedDeleteButton';
import { initialFlight, initialTrip } from '../../helpers';
import { fetchFlights, fetchTrips } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import TripPassengers from '../FormFields/TripPassengers';
import { useQueryClient } from 'react-query';
import FormHeader from '../FormFields/FormHeader';
import { trackTicketingForm } from '../../helpers/tracking';

const MultiCity: React.FC<FormikProps<{
  passenger: any;
  multiCity: { _id: string; flightInfo: any }[];
}>> = ({ values, setFieldValue, ...props }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    // checks if the ticket received from the backend has incorrectly ordered Ticket IDs by converting
    // last 8 hex characters to an integer
    const multiCityTripIds = values['multiCity']
      .map(trip => trip._id)
      .map(tripId => tripId.substr(16))
      .map(shortId => parseInt(shortId, 16));
    for (let idx = 1; idx < multiCityTripIds.length; idx++) {
      if (multiCityTripIds[idx] < multiCityTripIds[idx - 1]) {
        // we should have a monotonically increasing IDs; potentially sus-ly stored ticket in our database
        trackTicketingForm('multi-city', '-!ok', {
          uid: 'flaggedTripIdsOrder',
          info: `[${idx - 1}:${values['multiCity'][idx - 1]}]>[${idx}:${
            values['multiCity'][idx]
          }]`
        });
      }
    }
  }, [values]);

  async function addFlight(
    _f: any,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) {
    const token = await getAccessTokenSilently();
    const newTrip = {
      orderId: _f.orderId,
      ...initialTrip
    };
    const trip = await fetchTrips(token).create(newTrip);
    const newFlight = {
      orderId: _f.orderId,
      tripId: trip.payload.tripId,
      ...initialFlight
    };

    const flight = await fetchFlights(token).create(newFlight);

    fieldArrayRenderProps.push({
      _id: trip.payload.tripId,
      orderId: _f.orderId,
      flightInfo: [{ _id: flight.payload.flightId, ...newFlight }],
      ...initialTrip
    });
  }
  return (
    <FieldArray
      name="multiCity"
      render={arrayHelpers => (
        <>
          {values['multiCity'].map((currentFlight: any, index) => (
            <div key={`${currentFlight._id}_${index}`}>
              {index > 1 && (
                <RedCross
                  className="-ml-4 -mt-2"
                  arrayHelpers={arrayHelpers}
                  index={index}
                  onClick={async () => {
                    const token = await getAccessTokenSilently();
                    await fetchTrips(token).delete(currentFlight._id);
                    arrayHelpers.remove(index);
                    await queryClient.invalidateQueries('ticket');
                  }}
                />
              )}
              <div className="field-group-card">
                <FormHeader title={`Trip ${index + 1}`} loading={loading} />
                <TripFields
                  fieldGroup={`multiCity.${index}`}
                  setFieldValue={setFieldValue}
                  values={currentFlight}
                  setLoading={setLoading}
                  minDate={
                    // TODO: commented code below was causing weird datepicker issues
                    // values.multiCity[index - 1]?.flightInfo[
                    //   values.multiCity[index - 1]?.flightInfo.length - 1
                    // ]?.arriveDatetime ||
                    // TODO: so sticking with today -1 day as the minDate for now...
                    new Date(Date.now() - 86400000).toISOString().slice(0, -8)
                  }
                />
                <TripPassengers
                  tripId={currentFlight._id}
                  tripIdx={index}
                  values={values.passenger}
                  setLoading={setLoading}
                />
                <MultiCityFlightInfo
                  setFieldValue={setFieldValue}
                  addFlight={() => addFlight(currentFlight, arrayHelpers)}
                  flightIndex={index}
                  values={values}
                  {...props}
                />
              </div>
            </div>
          ))}
        </>
      )}
    />
  );
};

export default MultiCity;

import React from 'react';
import Status from '../Status';

interface FormHeaderProps {
  title: string;
  loading?: boolean;
  error?: boolean;
}

const FormHeader: React.FC<FormHeaderProps> = ({ title, loading, error }) => {
  return (
    <h2
      className={[
        'text-xl',
        'px-6',
        'py-3',
        'flex',
        error ? 'bg-red-900' : 'bg-gray-300',
        error ? 'text-white' : 'text-gray-800',
        error && 'font-bold'
      ].join(' ')}
    >
      <div className={['flex-grow', !error && 'uppercase'].join(' ')}>
        {title}
      </div>
      <div>{loading && <Status loading={loading} />}</div>
    </h2>
  );
};

export default FormHeader;

import { useState, useEffect, useRef } from 'react';

export function log(...messages: string[]) {
  console.log(new Date().toISOString(), ...messages);
}

export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (
      (typeof value === 'string' && value.length !== 1) ||
      typeof value !== 'string'
    ) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [delay, value]);

  return debouncedValue;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const initialTrip = {
  bookingReference: '',
  marketingAirline: '',
  totalTripDuration: { hours: 0, minutes: 0 },
  travelDate: '',
  origin: '',
  destination: '',
  ticketNumber: '',
  comment: ''
};
export const initialFlight = {
  airline: '',
  flightNumber: '',
  aircraftType: '',
  cabin: '',
  flightDuration: {
    hours: 0,
    minutes: 0
  },
  departDatetime: '',
  departAirportCode: '',
  arriveDateTime: '',
  arriveAirportCode: '',
  seatNumber: '',
  specialRequest: ''
};

export const tripTypeTranslate = {
  roundtrip: 'Round-Trip',
  oneway: 'One-Way',
  multicity: 'Multi-City'
};

import React, { useEffect, useState } from 'react';
import { FastField as Field } from 'formik';
import TextField from './TextField';
import SpecialRequestField from './RequestField';

interface FlightPassengersProps {
  flightId: string;
  values: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: any;
}

const FlightPassengers: React.FC<FlightPassengersProps> = ({
  flightId,
  values,
  setLoading,
  setFieldValue
}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
    } else {
      setFirstLoad(false);
    }
  }, [values, firstLoad, setLoading]);

  // this component should return for THIS Flight, every passenger.
  /* 
  "Flights": [
        {
          "FlightId": "62a3fadcd3904f6e0fdda6e7",
          "seatNumber": "ts",
          "specialRequest": ""
        },
        {
          "FlightId": "62a3fadcd3904f6e0fdda6e8",
          "seatNumber": "",
          "specialRequest": ""
        }
      ]
  */
  const flightPassengers = values.reduce((c: any, p: any, i: number) => {
    let flightIdx;
    const pFlight = p.flights?.find((p: any, j: number) => {
      flightIdx = j;
      return p.flightId === flightId;
    });
    c.push({
      name: `${p.firstName} ${p.middleName} ${p.lastName}`,
      passengerIndex: i,
      flightIndex: flightIdx,
      ...pFlight
    });
    return c;
  }, []);

  return (
    <>
      <div className="bg-gray-100 px-6 py-1 text-gray-800 uppercase border-gray-200 border-b-2 border-t-2 mt-4 flex">
        <div className="flex-grow">Flight Passengers</div>
      </div>
      {flightPassengers.map((passenger: any, i: number) => (
        <div
          key={`${passenger.passengerIndex}${passenger.flightIndex}`}
          className="md:grid grid-cols-9 gap-4 px-6 pb-2 rounded-b border-gray-200 border-b-2"
        >
          <div className="flex text-lg items-center col-span-2">
            <p className="w-full mr-8 mt-8">{passenger.name}</p>
          </div>
          <div className="col-span-3">
            <Field
              type="text"
              label="Seat Number"
              required
              name={`passenger[${passenger.passengerIndex}].flights[${passenger.flightIndex}].seatNumber`}
              as={TextField}
            />
          </div>
          <div className="col-span-4">
            <SpecialRequestField
              setFieldValue={setFieldValue}
              label="Special Request"
              parentField={values}
              name={`passenger[${passenger.passengerIndex}].flights[${passenger.flightIndex}].specialRequest`}
              flightIndex={passenger.flightIndex}
              passengerIndex={passenger.passengerIndex}
              ariaLabel="special request"
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default FlightPassengers;

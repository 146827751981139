import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated && user ? (
    <div className="flex gap-2">
      <div className="flex gap-2 items-center justify-center">
        <img
          className="rounded-full h-12 w-12"
          src={user.picture}
          alt={user.name}
        />
        <h2>Welcome {user.name}</h2>
      </div>
    </div>
  ) : null;
};

export default Profile;

import React, { useState } from 'react';
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik';
import RedCross from '../RedDeleteButton';
import FlightInfoFields from './FlightInfoFields';
import { flightInfo } from '../ticketingFields';
import { FieldGroupButton } from './FieldGroupButton';
import { initialFlight } from '../../helpers';
import { fetchFlights } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import Status from '../Status';
import { useQueryClient } from 'react-query';
import FlightPassengers from './FlightPassengers';

interface FlightInfoProps {
  fieldGroup: string | number;
}

const FlightInfo: React.FC<FormikProps<{
  [fieldGroup: string]: {
    _id: string;
    orderId: string;
    flightInfo: any;
    travelDate: string;
  };
}> &
  FlightInfoProps> = ({ values, fieldGroup, setFieldValue }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const nFlights = values.outbound.flightInfo.length - 1;

  async function handleAddLayover(arrayHelpers: FieldArrayRenderProps) {
    const token = await getAccessTokenSilently();
    const currentTrip = values[fieldGroup];

    const newFlight = {
      orderId: currentTrip.orderId,
      tripId: currentTrip._id,
      ...initialFlight
    };

    const flight = await fetchFlights(token).create(newFlight);
    arrayHelpers.push({
      _id: flight.payload.flightId,
      ...newFlight
    });

    await queryClient.invalidateQueries('ticket');
  }

  return (
    <FieldArray
      name={`${fieldGroup}.flightInfo`}
      render={arrayHelpers => (
        <div>
          {values[`${fieldGroup}`]?.flightInfo.map(
            (flight: any, index: number) => (
              <div
                className="border-blue-800 border-l-4 rounded-l"
                key={flight._id}
              >
                {index !== 0 && (
                  <RedCross
                    onClick={async () => {
                      const token = await getAccessTokenSilently();
                      await fetchFlights(token).delete(flight._id);
                      arrayHelpers.remove(index);
                      await queryClient.invalidateQueries('ticket');
                    }}
                    className="-ml-4 -mt-3"
                    arrayHelpers={arrayHelpers}
                    index={index}
                  />
                )}
                <div className="text-lg bg-gray-200 px-6 py-2 text-gray-800 uppercase border-gray-400 border-b-2 border-t-4 flex">
                  <div className="flex-grow">
                    {`${fieldGroup}`} Flight {index + 1}
                  </div>
                  <div>
                    <Status loading={loading} />
                  </div>
                </div>
                <FlightInfoFields
                  flight={flight}
                  fieldGroup={`${fieldGroup}.flightInfo.${index}`}
                  minDepartDatetime={
                    (index === 0 &&
                      ((values[fieldGroup].travelDate &&
                        new Date(values[fieldGroup].travelDate)
                          .toISOString()
                          .slice(0, -8)) ||
                        (fieldGroup === 'return' &&
                          values.outbound.flightInfo[nFlights]
                            ?.arriveDatetime))) || // outbound
                    values[fieldGroup].flightInfo[index - 1]?.arriveDatetime ||
                    new Date(Date.now()).toISOString().slice(0, -8)
                  }
                  setFieldValue={setFieldValue}
                  setLoading={setLoading}
                />
                <FlightPassengers
                  flightId={values[fieldGroup].flightInfo[index]._id}
                  values={values.passenger}
                  setLoading={setLoading}
                  setFieldValue={setFieldValue}
                />
              </div>
            )
          )}
          <FieldGroupButton onClick={() => handleAddLayover(arrayHelpers)}>
            + ADD LAYOVER
          </FieldGroupButton>
        </div>
      )}
    />
  );
};

export default FlightInfo;

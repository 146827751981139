import React from 'react';
import { Link } from 'react-router-dom';

export const Homepage = () => {
  return (
    <div className="flex-col">
      <h2 className="text-xl font-semibold text-center">
        Welcome to your Flow
      </h2>
      <Link to="/tickets">
        <button className="border p-4 bg-blue-700 m-4 rounded hover:bg-blue-600">
          Tickets
        </button>
      </Link>
    </div>
  );
};

export default Homepage;

export const email = {
  to: '',
  cc: '',
  bcc: '',
  subject: ''
};

export const phone = {
  dialCode: '1',
  iso2: 'us',
  number: ''
};

export const passenger = {
  type: 'adult',
  firstName: '',
  middleName: '',
  lastName: '',
  flights: [],
  trips: []
};

export const flightInfo = {
  airlines: '',
  flightNumber: '',
  aircraftType: '',
  cabin: '',
  flightDuration: {
    hours: 0,
    minutes: 0
  },
  departDate: '',
  departTime: '',
  departAirportCode: '',
  arriveDate: '',
  arriveTime: '',
  arriveAirportCode: '',
  seatNumber: '',
  specialRequest: '',
  comment: ''
};

export const flight = {
  bookingReference: '',
  marketingAirline: '',
  totalTripDuration: { hours: 0, minutes: 0 },
  travelDate: '',
  origin: '',
  destination: '',
  passengers: {
    adult: 1,
    child: 0,
    infant: 0
  },
  ticketNumber: '',
  comment: '',
  flightInfo: [flightInfo]
};

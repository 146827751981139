import React from 'react';
import { Link } from 'react-router-dom';

const ETicketConfirmation: React.FC<any> = () => {
  return (
    <div className="flex flex-col justify-center items-center space-y-4 my-4">
      <div>Your ticket has been submitted</div>
      <Link to="/tickets">
        <button className="bg-blue-500 text-blue-100 hover:bg-blue-400 p-3 rounded">
          Return to Tickets
        </button>
      </Link>
    </div>
  );
};

export default ETicketConfirmation;

import React, { useEffect, useState } from 'react';
import { FastField as Field, Field as FormikField } from 'formik';
import TextField from './TextField';
import AirportField from './AirportField';
import { log, useDebounce } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchTrips } from '../../api';
import AirlineField from './AirlineField';
import { useParams } from 'react-router-dom';
import { trackTripFieldRendered } from '../../helpers/tracking';

interface TripFieldsProps {
  fieldGroup: string | number;
  setFieldValue: any;
  values: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  minDate: string;
  trackingContext?: { tripId: string; renderIdx: number };
}

/* 
  calculate the time difference between each values.flightInfo and add them to each values.flightInfo.tripDuration.hours/minutes
*/
function calculateTimeDifference(t1: string, t2: string) {
  const millis = new Date(t2).getTime() - new Date(t1).getTime();
  const seconds = millis / 1000;
  const minutes = seconds / 60;
  const hours = Math.floor(minutes / 60);
  const minutes_mod = minutes % 60;

  return {
    hours,
    minutes: minutes_mod
  };
}

function calculateTotalTripDuration(trip: { flightInfo: any }) {
  function calculateLayover(
    prev: { hours: number; minutes: number },
    curr: any,
    index: number
  ) {
    const nextFlight = trip.flightInfo[index + 1];
    if (nextFlight) {
      const layover = calculateTimeDifference(
        curr.arriveDatetime,
        nextFlight.departDatetime
      );
      prev.hours += layover.hours;
      prev.minutes += layover.minutes;
    }

    return prev;
  }

  function calculateFlightDuration(
    prev: { hours: number; minutes: number },
    curr: any
  ) {
    prev.hours += curr.flightDuration.hours || 0;
    prev.minutes += curr.flightDuration.minutes || 0;

    return prev;
  }

  const totalLayoverDuration = trip.flightInfo.reduce(calculateLayover, {
    hours: 0,
    minutes: 0
  });

  const totalFlightDuration = trip.flightInfo.reduce(calculateFlightDuration, {
    hours: 0,
    minutes: 0
  });

  const totalTripDuration = {
    hours: totalLayoverDuration.hours + totalFlightDuration.hours,
    minutes: totalLayoverDuration.minutes + totalFlightDuration.minutes
  };

  if (totalTripDuration.minutes >= 60) {
    const hoursFromMinutes = Math.floor(totalTripDuration.minutes / 60);
    totalTripDuration.hours += hoursFromMinutes;
    totalTripDuration.minutes = totalTripDuration.minutes % 60;
  }

  return totalTripDuration;
}

const TripFields: React.FC<TripFieldsProps> = ({
  fieldGroup,
  setFieldValue,
  values,
  setLoading,
  minDate,
  trackingContext
}) => {
  const [prevTrip, setPrevTrip] = useState(values);
  const params = useParams<{ id: string }>();
  const { getAccessTokenSilently, user } = useAuth0();
  const debouncedTrip = useDebounce(values, 500);
  const [firstLoad, setFirstLoad] = useState(true);
  const [tripDuration, setTripDuration] = useState(values.totalTripDuration);

  useEffect(() => {
    // get modified flight fields as an array, add them as $set
    // on change, collect the values into an array
    // then turn them into an object to send to
    const diff: {
      flightInfo?: any;
      totalTripDuration?: { hours: number; minutes: number };
    } = Object.keys(debouncedTrip).reduce((prev, field) => {
      return debouncedTrip[field] !== prevTrip[field]
        ? { ...prev, [field]: debouncedTrip[field] }
        : prev;
    }, {});

    // if there are any changes to the flight duration, calculate the total trip time
    if (diff.flightInfo) {
      // calculate the layover for each flight.
      const newTripDuration = calculateTotalTripDuration(debouncedTrip);
      setTripDuration(newTripDuration);
      diff.totalTripDuration = newTripDuration;
      // setFieldValue(`${fieldGroup}.totalTripDuration`, tripDuration)

      // dont include updates to flight info because this is handle in each flight info component
      delete diff.flightInfo;
    }

    if (Object.keys(diff).length > 0) {
      updateTrip(diff).then(() => {
        setPrevTrip(debouncedTrip);
      });
    }
    setLoading(false);
    //eslint-disable-next-line
  }, [debouncedTrip]);

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
    } else {
      setFirstLoad(false);
    }
    //eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    if (trackingContext !== undefined) {
      trackTripFieldRendered(trackingContext, values);
    }
  }, [trackingContext, values]);

  async function updateTrip(trip: any) {
    if (user?.email) {
      const token = await getAccessTokenSilently();
      fetchTrips(token).update(values._id, params.id, user.email, trip);
      log('Updated trip fields', trip);
    } else {
      throw Error('User is missing, please authenticate');
    }
  }
  return (
    <div className="md:grid grid-cols-3 gap-4 mx-6 mt-4 rounded-b">
      <Field
        type="text"
        label="Booking reference"
        required
        name={`${fieldGroup}.bookingReference`}
        as={TextField}
      />
      <AirlineField
        setFieldValue={setFieldValue}
        label="Marketing Airline"
        required
        parentField={values}
        name={`${fieldGroup}.marketingAirline`}
        ariaLabel="Airlines"
      />
      <TextField
        label="Total trip duration"
        type="text"
        value={`${tripDuration.hours}H ${tripDuration.minutes}M`}
        name={`${fieldGroup}.tripDuration`}
        required={false}
        readOnly
      />
      {/* <FormikField
        type="text"
        label="Total trip duration"
        required={false}
        value={`${tripDuration.hours}H ${tripDuration.minutes}M`}
        name={`${fieldGroup}.tripDuration`}
        as={TextField}
      /> */}
      <FormikField
        type="date"
        label="Travel date"
        required
        min={new Date(minDate || Date.now()).toISOString().split('T')[0]}
        name={`${fieldGroup}.travelDate`}
        as={TextField}
      />
      <AirportField
        setFieldValue={setFieldValue}
        label="Origin"
        required
        parentField={values}
        name={`${fieldGroup}.origin`}
        ariaLabel="Origin"
      />
      <AirportField
        setFieldValue={setFieldValue}
        label="Destination"
        required
        parentField={values}
        name={`${fieldGroup}.destination`}
        ariaLabel="Destination"
      />
    </div>
  );
};

export default TripFields;

import React from 'react';

interface CabinFieldProps {
  label: string;
  required: boolean;
}

const CabinField: React.FC<CabinFieldProps> = ({
  label,
  required,
  ...props
}) => {
  return (
    <div className="mt-2">
      <label>
        {label} <span className="text-red-500">{required ? '*' : ''}</span>
      </label>
      <div>
        <select
          className="border rounded bg-gray-100 p-3 mt-2 w-full"
          {...props}
        >
          <option value="Basic Economy">Basic Economy</option>
          <option value="Economy">Economy</option>
          <option value="Premium Economy">Premium Economy</option>
          <option value="Business">Business</option>
          <option value="First">First</option>
        </select>
      </div>
    </div>
  );
};

export default CabinField;

import React from 'react';
import { FileReaderResult } from '../../types';

interface AttachmentProps {
  file: FileReaderResult;
  handleRemove: (id: string) => void;
}

const Attachment: React.FC<AttachmentProps> = ({ file, handleRemove }) => {
  return (
    <div className="p-2 border rounded flex justify-center items-center space-x-4">
      <p>{file.name}</p>
      <button
        className="text-red-400 px-2 text-xl"
        onClick={() => handleRemove(file.id)}
      >
        &times;
      </button>
    </div>
  );
};

export default Attachment;

import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { email, passenger } from '../../components/ticketingFields';
import {
  QueryObserverIdleResult,
  QueryObserverLoadingErrorResult,
  QueryObserverRefetchErrorResult,
  QueryObserverSuccessResult
} from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { fetchTickets } from '../../api';
import { tripTypeTranslate, useDebounce } from '../../helpers';
import debounce from 'lodash.debounce';

interface Props {
  tickets: any;
  queryResult:
    | QueryObserverIdleResult<any, unknown>
    | QueryObserverLoadingErrorResult<any, unknown>
    | QueryObserverRefetchErrorResult<any, unknown>
    | QueryObserverSuccessResult<any>;
}

export const TicketList: React.FC<Props> = ({ tickets, queryResult }) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();

  const [filter, setFilter] = useState<'drafts' | 'submitted'>('drafts');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search.toLowerCase(), 500);
  const MAX_MS = 8640000000000000;

  async function handleClick() {
    const token = await getAccessTokenSilently();
    const body = {
      agentEmail: user?.email,
      email: { ...email },
      phone: { dialCode: '+1', iso2: 'us', number: '' },
      passenger: [passenger]
    };

    fetchTickets(token)
      .create(body)
      .then(json => {
        const orderId = json.payload.orderId;

        return history.push(`/order/${orderId}`);
      });
  }

  const debouncedHandleClick = debounce(handleClick, 10000, {
    leading: true,
    trailing: false
  });

  async function handleDelete(orderId: string) {
    const token = await getAccessTokenSilently();
    fetchTickets(token)
      .delete(orderId)
      .then(() => queryResult.refetch());
  }

  if (!tickets) return null;

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center border-t-4 border-blue-800 rounded-t-md">
          <div className="sm:flex-auto items-center px-3 py-3 text-gray-800 shadow bg-white flex rounded-b-md">
            <h1 className="text-xl mr-16">Tickets</h1>
            <div className="flex space-x-8 flex-grow">
              <button
                className={`mt-2 p-2 border-b-2 border-blue-600 rounded-t-md ${
                  filter === 'drafts'
                    ? 'bg-blue-500 text-blue-100 rounded-b-md'
                    : 'text-gray-700'
                }`}
                onClick={() => setFilter('drafts')}
              >
                Drafts
              </button>
              <button
                className={`mt-2 p-2 border-b-2 border-blue-600 rounded-t-md ${
                  filter === 'submitted'
                    ? 'bg-blue-500 text-blue-100 rounded-b-md'
                    : 'text-gray-700'
                }`}
                onClick={() => setFilter('submitted')}
              >
                Submitted
              </button>
            </div>
            <div className="px-3 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                onClick={debouncedHandleClick}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Create Ticket
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="sm:flex sm:items-center rounded-md">
            <div className="sm:flex-auto items-center shadow bg-white flex rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mx-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
              <input
                placeholder="Search"
                onChange={e => setSearch(e.target.value)}
                className="border-l px-3 py-3 text-gray-800 w-full"
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Travel Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Final Arrival Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Customer
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Owner
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Assignee
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Trip Type
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tickets
                      ?.filter((order: any) => {
                        return (
                          order.email.to
                            ?.toLowerCase()
                            ?.includes(debouncedSearch) ||
                          order.agent.toLowerCase().includes(debouncedSearch) ||
                          order.assignee
                            ?.toLowerCase()
                            ?.includes(debouncedSearch) ||
                          order.tripType.includes(debouncedSearch) ||
                          order.travelDate?.includes(debouncedSearch) ||
                          `${order.passenger[0].firstName} ${order.passenger[0].lastName}`
                            ?.toLowerCase()
                            .includes(debouncedSearch.toLowerCase()) ||
                          order._id?.toLowerCase()?.includes(debouncedSearch)
                        );
                      })
                      ?.filter((order: any) => {
                        switch (filter) {
                          case 'drafts': {
                            return (
                              new Date(order.travelDate || MAX_MS).getTime() >=
                                new Date(Date.now()).getTime() &&
                              !order.submitted
                            );
                          }
                          case 'submitted': {
                            return (
                              order.submitted &&
                              (new Date(order.finalArrivalDate).getTime() +
                                1000 * 60 * 60 * 24 * 2 >
                                new Date(
                                  Date.now()
                                ).getTime() /*2 days in millis*/ ||
                                order.agent === user?.email ||
                                order.assignee === user?.email)
                            );
                          }
                          default:
                            break;
                        }
                      })
                      .map((order: any, i: number) => (
                        <tr key={order._id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                            {order.travelDate || (
                              <span className="text-gray-500">No date</span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                            {order.finalArrivalDate || (
                              <span className="text-gray-500">No date</span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                            {order.email.to ? (
                              order.email.to
                            ) : (
                              <span className="text-gray-500">
                                No recipient
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                            {order.agent}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                            {order.assignee || order.agent}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                            {
                              tripTypeTranslate[
                                order.tripType as keyof typeof tripTypeTranslate
                              ]
                            }
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700">
                            {order.submitted ? 'Submitted' : 'Draft'}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 space-x-6">
                            <Link
                              className="text-gray-300 hover:text-gray-500 font-bold"
                              to={`/compatible/${order._id}`}
                            >
                              &lt;/&gt;
                              <span className="sr-only">
                                , to {order.email.to}
                              </span>
                            </Link>
                            <Link
                              className="text-blue-600 hover:text-blue-900"
                              to={`/order/${order._id}`}
                            >
                              Edit
                              <span className="sr-only">
                                , to {order.email.to}
                              </span>
                            </Link>
                            <button
                              className="text-red-600 hover:text-red-400"
                              onClick={() => handleDelete(order._id)}
                            >
                              Delete
                              <span className="sr-only">
                                , to {order.email.to}
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useQuery } from 'react-query';
import { TicketList } from './TicketList';

export const SupervisorTicketContainer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const ordersResult = useQuery('supervisorOrders', async () => {
    const token = await getAccessTokenSilently();
    const json = await fetch(
      `${process.env.REACT_APP_API_URL}/api/agent/all/orders`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 403) throw new Error('Unauthorized');
      })
      .catch(e => {
        console.error(e);
      });

    return json;
  });

  const orders = ordersResult?.data?.payload?.orders;

  if (ordersResult.isLoading) return null;
  if (!orders)
    return <div className="p-4">You are not authorized to use this page.</div>;

  return <TicketList tickets={orders} queryResult={ordersResult} />;
};

import React, { useState } from 'react';
import { FieldArray, FormikProps } from 'formik';
import RedCross from '../RedDeleteButton';
import FlightInfoFields from './FlightInfoFields';
import { FieldGroupButton } from './FieldGroupButton';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchFlights } from '../../api';
import { initialFlight } from '../../helpers';
import Status from '../Status';
import FlightPassengers from './FlightPassengers';
import { useQueryClient } from 'react-query';

interface MultiCityFlightInfoProps {
  flightIndex: number;
  addFlight: () => Promise<void>;
}

const MultiCityFlightInfo: React.FC<FormikProps<any> &
  MultiCityFlightInfoProps> = ({
  values,
  flightIndex,
  addFlight,
  setFieldValue
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const nFlights = values.multiCity[flightIndex - 1]?.flightInfo.length - 1;

  return (
    <FieldArray
      name={`multiCity.${flightIndex}.flightInfo`}
      render={arrayHelpers => (
        <div>
          {values[`multiCity`][flightIndex].flightInfo.map(
            (flight: any, index: number) => (
              <div
                key={flight._id}
                className="border-blue-800 border-l-4 rounded-l"
              >
                <>
                  {index !== 0 && (
                    <RedCross
                      onClick={async () => {
                        const token = await getAccessTokenSilently();
                        await fetchFlights(token).delete(flight._id);
                        arrayHelpers.remove(index);
                        await queryClient.invalidateQueries('ticket');
                      }}
                      className="-ml-4 -mt-3"
                      arrayHelpers={arrayHelpers}
                      index={index}
                    />
                  )}
                  <div className="text-lg bg-gray-200 px-6 py-2 text-gray-800 uppercase border-gray-400 border-b-2 border-t-4 flex">
                    <div className="flex-grow">
                      {`Multicity Trip ${flightIndex + 1}`} Flight {index + 1}
                    </div>
                    <div>
                      <Status loading={loading} />
                    </div>
                  </div>
                  <FlightInfoFields
                    flight={flight}
                    fieldGroup={`multiCity.${flightIndex}.flightInfo.${index}`}
                    minDepartDatetime={
                      (index === 0 &&
                        ((values.multiCity[flightIndex]?.travelDate &&
                          new Date(values.multiCity[flightIndex]?.travelDate)
                            .toISOString()
                            .slice(0, -8)) ||
                          values.multiCity[flightIndex - 1]?.flightInfo[
                            nFlights
                          ]?.arriveDatetime)) ||
                      values.multiCity[flightIndex]?.flightInfo[index - 1]
                        ?.arriveDatetime ||
                      new Date(Date.now()).toISOString().slice(0, -8)
                    }
                    setFieldValue={setFieldValue}
                    setLoading={setLoading}
                  />
                  <FlightPassengers
                    flightId={
                      values['multiCity'][flightIndex].flightInfo[index]._id
                    }
                    values={values.passenger}
                    setLoading={setLoading}
                    setFieldValue={setFieldValue}
                  />
                </>
              </div>
            )
          )}
          <div className="flex">
            <FieldGroupButton
              onClick={async () => {
                await addFlight();
                await queryClient.invalidateQueries('ticket');
              }}
            >
              + ADD FLIGHT
            </FieldGroupButton>
            <FieldGroupButton
              onClick={async () => {
                const token = await getAccessTokenSilently();
                const currentTrip = values[`multiCity`][flightIndex];
                const newFlight = {
                  orderId: currentTrip.orderId,
                  tripId: currentTrip._id,
                  ...initialFlight
                };

                const flight = await fetchFlights(token).create(newFlight);
                arrayHelpers.push({
                  _id: flight.payload.flightId,
                  ...newFlight
                });

                await queryClient.invalidateQueries('ticket');
              }}
            >
              + ADD LAYOVER
            </FieldGroupButton>
          </div>
        </div>
      )}
    />
  );
};

export default MultiCityFlightInfo;

import { apiUrl } from '../config';
import { log } from '../helpers';

function crudFactory(
  type: string,
  token: string,
  urlString: string = `${apiUrl}/api/${type}`
) {
  const urlStringId = (id: string) => `${urlString}/${id}`;

  return {
    get: {
      one: async (id: string) => {
        const data = await fetch(urlStringId(id), {
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(response => response.json())
          .then(json => json)
          .catch(e => console.error(e));

        return data;
      }
    },
    create: async (rawBody: any) => {
      const data = await fetch(urlString, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(rawBody)
      })
        .then(res => res.json())
        .then(json => json)
        .catch(e => console.error(e));

      return data;
    },
    update: async (
      id: string,
      orderId: string,
      agent: string,
      rawBody: any
    ) => {
      const data = await fetch(urlStringId(id), {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(rawBody)
      })
        .then(res => res.json())
        .then(json => json)
        .catch(e => console.error(e));

      await fetch(`${apiUrl}/api/order/${orderId}/assignee`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ assignee: agent })
      })
        .then(res => res.json())
        .then(json => json)
        .catch(e => console.error(e));

      return data;
    },
    delete: async (id: string) => {
      const data = await fetch(urlStringId(id), {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => res.json())
        .then(json => json)
        .catch(e => console.error(e));

      return data;
    }
  };
}

export function fetchOrders(token: string) {
  const type = 'order';
  const api = crudFactory(type, token);

  const extendedApi = {
    ...api,
    get: {
      ...api.get,
      one: async (id: string) => {
        const data = await fetch(`${apiUrl}/api/${type}/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(response => response.json())
          .then(json => json)
          .catch(e => console.error(e));

        return {
          data,
          passengers: () => {
            return crudFactory(
              'passengers',
              token,
              `${apiUrl}/api/${type}/${id}/passenger`
            );
          }
        };
      }
    }
  };

  return extendedApi;
}

export function fetchTrips(token: string) {
  const api = crudFactory('trip', token);

  const extendedApi = {
    ...api,
    get: {
      ...api.get,
      byOrder: async (id: string) => {
        const data = await fetch(`${apiUrl}/api/order/${id}/trips`, {
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(response => response.json())
          .then(json => json)
          .catch(e => console.error(e));
        log('Fetched trip by order', data);
        return data;
      }
    }
  };

  return extendedApi;
}

export function fetchFlights(token: string) {
  const api = crudFactory('flight', token);

  const extendedApi = {
    ...api,
    get: {
      ...api.get,
      byOrder: async (id: string) => {
        const data = await fetch(`${apiUrl}/api/order/${id}/flights`, {
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(response => response.json())
          .then(json => json)
          .catch(e => console.error(e));

        return data;
      },
      byTrip: async (id: string) => {
        const data = await fetch(`${apiUrl}/api/trip/${id}/flights`, {
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(response => response.json())
          .then(json => json)
          .catch(e => console.error(e));

        return data;
      }
    }
  };

  return extendedApi;
}

export function fetchTickets(token: string) {
  const api = crudFactory('ticket', token);

  const extendedApi = {
    ...api,
    get: {
      ...api.get,
      byOrder: async (id: string) => {
        const data = await fetch(`${apiUrl}/api/ticket/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(response => response.json())
          .then(json => json)
          .catch(e => console.error(e));

        return data;
      }
    }
  };

  return extendedApi;
}

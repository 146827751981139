import React, { useEffect, useRef, useState } from 'react';
import { FastField as Field, Field as FormikField } from 'formik';
import TextField from './TextField';
import AirportField from './AirportField';
import { fetchFlights } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { log, useDebounce } from '../../helpers';
import CabinField from './CabinField';
import AirlineField from './AirlineField';
import { useParams } from 'react-router-dom';

interface FlightInfoFieldsProps {
  fieldGroup: string;
  setFieldValue: any;
  flight: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  minDepartDatetime: string;
}

const FlightInfoFields: React.FC<FlightInfoFieldsProps> = ({
  fieldGroup,
  setFieldValue,
  flight,
  setLoading,
  minDepartDatetime
}) => {
  const [prevFlight, setPrevFlight] = useState(flight);
  const params = useParams<{ id: string }>();
  const { getAccessTokenSilently, user } = useAuth0();
  const debouncedFlight = useDebounce(flight, 500);
  const [firstLoad, setFirstLoad] = useState(true);
  // const [flightDuration, setFlightDuraton] = useState(
  //   calculateTimeDifference(flight.departDatetime, flight.arriveDatetime)
  // );
  const minArriveDate = useRef<undefined | string>(
    new Date(Date.now() - 2 * 86400000).toISOString().slice(0, -8)
  );

  useEffect(() => {
    // get modified flight fields as an array, add them as $set
    // on change, collect the values into an array
    // then turn them into an object to send to
    const diff: {
      departDatetime?: string;
      arriveDatetime?: string;
      flightDuration?: string;
    } = Object.keys(debouncedFlight).reduce((prev, field) => {
      return debouncedFlight[field] !== prevFlight[field]
        ? { ...prev, [field]: debouncedFlight[field] }
        : prev;
    }, {});

    if (Object.keys(diff).length > 0) {
      updateFlight(diff).then(() => {
        const lastFlightDate = new Date(
          debouncedFlight.departDatetime || Date.now()
        );
        lastFlightDate.setTime(lastFlightDate.getTime() - 86400000);
        minArriveDate.current =
          lastFlightDate.toISOString().slice(0, -8) || minArriveDate.current;
        setPrevFlight(debouncedFlight);
      });
    }
    setLoading(false);
    //eslint-disable-next-line
  }, [debouncedFlight]);

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
    } else {
      setFirstLoad(false);
    }
    //eslint-disable-next-line
  }, [flight]);

  async function updateFlight(values: any) {
    if (user?.email) {
      const token = await getAccessTokenSilently();
      await fetchFlights(token).update(
        flight._id,
        params.id,
        user.email,
        values
      );
      log('Updated flight fields', values);
    } else {
      throw Error('User is missing, please authenticate');
    }
  }

  if (!flight) return null;

  return (
    <>
      <div className="md:grid grid-cols-3 gap-4 mx-6 mt-4">
        <AirlineField
          setFieldValue={setFieldValue}
          label="Airlines"
          required
          parentField={flight}
          name={`${fieldGroup}.airline`}
          ariaLabel="Airlines"
        />
        <Field
          type="text"
          label="Flight number"
          name={`${fieldGroup}.flightNumber`}
          as={TextField}
        />
        <Field
          type="text"
          label="Aircraft type"
          required
          name={`${fieldGroup}.aircraftType`}
          as={TextField}
        />
        <div className="grid grid-cols-9 col-span-3 gap-4">
          <div className="col-span-3">
            <Field
              type="text"
              label="Cabin"
              required
              name={`${fieldGroup}.cabin`}
              as={CabinField}
            />
          </div>
          {/* 
          { 
            ...
            flightDuration: {
              hours: number,
              minutes: number,
            }
            ...
          }
        */}
          <FormikField
            type="number"
            label="Hours"
            required
            name={`${fieldGroup}.flightDuration.hours`}
            as={TextField}
          />
          <FormikField
            type="number"
            label="Minutes"
            min="0"
            max="59"
            required
            name={`${fieldGroup}.flightDuration.minutes`}
            as={TextField}
          />
          <div className="col-span-4">
            <Field
              type="text"
              label="Comment"
              name={`${fieldGroup}.comment`}
              as={TextField}
            />
          </div>
        </div>
      </div>
      <div className="md:grid grid-cols-2 gap-4 mx-6 mt-4">
        <FormikField
          type="datetime-local"
          label="Depart date"
          required
          min={minDepartDatetime}
          name={`${fieldGroup}.departDatetime`}
          as={TextField}
        />
        <AirportField
          setFieldValue={setFieldValue}
          label="Depart airport code"
          required
          parentField={flight}
          name={`${fieldGroup}.departAirportCode`}
          ariaLabel="Depart airport code"
        />
        <FormikField
          type="datetime-local"
          label="Arrive date"
          required
          name={`${fieldGroup}.arriveDatetime`}
          as={TextField}
        />
        <AirportField
          setFieldValue={setFieldValue}
          label="Arrive airport code"
          required
          parentField={flight}
          name={`${fieldGroup}.arriveAirportCode`}
          ariaLabel="Arrive airport code"
        />
      </div>
    </>
  );
};

export default FlightInfoFields;

import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import LoginButton from './Auth/LoginButton';
import LogoutButton from './Auth/LogoutButton';
import Profile from './Auth/Profile';
import logo from '../assets/logo.svg';
import { Link, NavLink } from 'react-router-dom';

export const Navbar = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <div className="flex shadow p-3 border-b w-full fixed bg-white z-40">
      <Link to="/">
        <img src={logo} alt="" />
      </Link>
      <div className="flex-grow flex items-end ml-48 space-x-8">
        <NavLink
          to="/"
          exact
          className="border-b-2 border-white text-xl px-3"
          activeClassName="border-b-2 border-blue-800"
        >
          Home
        </NavLink>
        <NavLink
          to="/tickets"
          className="border-b-2 border-white text-xl px-3"
          activeClassName="border-b-2 border-blue-800"
        >
          Tickets
        </NavLink>
        <NavLink
          to="/supervisor"
          className="border-b-2 border-white text-xl px-3"
          activeClassName="border-b-2 border-blue-800"
        >
          Supervisor
        </NavLink>
      </div>
      <div className="flex gap-4">
        <Profile />
        {isAuthenticated ? (
          <LogoutButton />
        ) : (
          <>
            <LoginButton />
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;

import React, { useCallback, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { useQueryClient } from 'react-query';
import FormHeader from './FormHeader';

interface LogoProps {
  name: string;
  updateOrder(values: any): Promise<void>;
  tripType: string;
  setTripType: React.Dispatch<React.SetStateAction<string>>;
  logo: string;
  setLogo: React.Dispatch<React.SetStateAction<string>>;
}

const Logo: React.FC<FormikProps<any> & LogoProps> = ({
  values,
  name,
  updateOrder,
  tripType,
  setTripType,
  logo,
  setLogo
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const initialLoad = useCallback(() => {
    if (!firstLoad) {
      setLoading(true);
      const updatedTrip: { tripType?: any; logo?: any } = {};
      if (values.tripType !== tripType) {
        updatedTrip.tripType = tripType;
      }
      if (values.logo !== logo) {
        updatedTrip.logo = logo;
      }
      if (Object.keys(updatedTrip).length > 0) {
        updateOrder(updatedTrip);
      }
      setLoading(false);
    } else {
      setFirstLoad(false);
    }
  }, [logo, tripType, firstLoad, updateOrder, values.logo, values.tripType]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <div className="field-group-card">
      <FormHeader title="LOGO" loading={loading} />
      <div className="mx-6 mt-6 flex space-x-8">
        <div className="flex space-x-4 justify-center items-center">
          <label>Logo</label>
          <select
            value={logo}
            onChange={async e => {
              setLogo(e.target.value);
              await queryClient.invalidateQueries('ticket');
            }}
            className="border rounded bg-gray-100 p-3 mt-2"
          >
            <option value="goflyfirst">GOFLYFIRST</option>
            <option value="gofly">GOFLY</option>
            <option value="none">NONE</option>
          </select>
        </div>
        <div className="flex space-x-4 justify-center items-center">
          <label>Trip Type</label>
          <select
            value={tripType}
            onChange={async e => {
              setTripType(e.target.value);
              await queryClient.invalidateQueries('ticket');
            }}
            className="border rounded bg-gray-100 p-3 mt-2"
          >
            <option value="roundtrip">Round-Trip</option>
            <option value="oneway">One-Way</option>
            <option value="multicity">Multi-City</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Logo;

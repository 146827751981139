import React, { useState } from 'react';

interface Props {
  jsons: string[];
  collapse?: boolean;
}

const Debug: React.FC<Props> = ({ jsons, collapse = false }) => {
  const [debug, setDebug] = useState(!collapse);
  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <button
          className="bg-gray-700 p-1 rounded text-gray-100 text-xs"
          onClick={() => setDebug(!debug)}
        >
          Debug
        </button>
      )}
      {debug && (
        <>
          {jsons.map((j, i) => (
            <pre key={i} className="border p-1 mt-2 text-xs">
              {JSON.stringify(j, null, 2)}
            </pre>
          ))}
        </>
      )}
    </>
  );
};

export default Debug;

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000';
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID || '';
export const auth0Audience =
  process.env.REACT_APP_AUTH0_AUDIENCE || 'http://localhost:3000';

export const baseApiLambdaURL =
  process.env.REACT_APP_LAMBDA_URL || 'http://localhost:8000';

export const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

import React, { useEffect, useState } from 'react';
import { FieldArray, FormikProps, Field, FieldArrayRenderProps } from 'formik';
import TextField from './TextField';
import RedCross from '../RedDeleteButton';
import { FieldGroupButton } from './FieldGroupButton';
import { fetchOrders } from '../../api';
import { useAuth0 } from '@auth0/auth0-react';
import { useDebounce } from '../../helpers';
import { passenger } from '../ticketingFields';
import { useMutation } from 'react-query';
import FormHeader from './FormHeader';

type PassengerProps = {
  updateOrder(values: any): Promise<void>;
  orderId: string;
  errors: { passenger?: { [key: string]: any }[]; [key: string]: any };
};
const Passenger: React.FC<FormikProps<{ _id: string; passenger: {}[] }> &
  PassengerProps> = ({ values, updateOrder, orderId, ...props }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const debouncedPx = useDebounce(values.passenger, 500);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { mutate: passengerMutation } = useMutation((newPassenger: any) => {
    return updateOrder({ passenger: newPassenger });
  });

  useEffect(() => {
    if (!firstLoad) {
      passengerMutation(debouncedPx);
      setLoading(false);
    } else {
      setFirstLoad(false);
    }
  }, [debouncedPx, firstLoad, passengerMutation]);

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
    }
  }, [values.passenger, firstLoad]);

  async function handleAddPassenger(arrayHelpers: FieldArrayRenderProps) {
    const token = await getAccessTokenSilently();
    const order = await fetchOrders(token).get.one(orderId);
    const passengerResult = await order.passengers().create(passenger);

    arrayHelpers.push(passengerResult.payload.passenger);
  }
  if (!values.passenger) return null;

  return (
    <div className="field-group-card">
      <FormHeader title="PASSENGER" loading={loading} />
      <FieldArray
        name="passenger"
        render={arrayHelpers => (
          <>
            {values.passenger.map((passenger, index) => (
              <div
                key={index}
                className="md:grid grid-cols-4 gap-4 mx-6 mt-4 pb-4"
              >
                {index !== 0 && (
                  <RedCross
                    onClick={() => {
                      arrayHelpers.remove(index);
                    }}
                    className="-ml-10"
                    arrayHelpers={arrayHelpers}
                    index={index}
                  />
                )}
                <div className="mt-2">
                  <label>
                    Type <span className="text-red-500">{'*'}</span>
                  </label>
                  <Field
                    as="select"
                    label="Last name"
                    required
                    name={`passenger.${index}.type`}
                    className="border rounded bg-gray-100 p-3 mt-2 w-full"
                  >
                    <option value="adult">Adult</option>
                    <option value="child">Child</option>
                    <option value="infant">Infant</option>
                  </Field>
                </div>
                <Field
                  type="text"
                  label="First name"
                  required
                  name={`passenger.${index}.firstName`}
                  as={TextField}
                  // {...props}
                />
                <Field
                  type="text"
                  label="Middle name"
                  name={`passenger.${index}.middleName`}
                  as={TextField}
                  // {...props}
                />
                <Field
                  type="text"
                  label="Last name"
                  required
                  name={`passenger.${index}.lastName`}
                  as={TextField}
                  // {...props}
                />
              </div>
            ))}
            <FieldGroupButton onClick={() => handleAddPassenger(arrayHelpers)}>
              + ADD PASSENGER
            </FieldGroupButton>
          </>
        )}
      />
    </div>
  );
};

export default Passenger;

import { gaMeasurementId } from '../config';
import ReactGA from 'react-ga4';
import { AmazonXrayTraceId } from './response';

export type TrackingGenericInfoSerializable = {
  uid: string;
  info: string;
};
type TrackingSerializable = Response | Error | TrackingGenericInfoSerializable;
type TicketFormTrackingCategory =
  | 'submit'
  | 'preview'
  | 'submit-update'
  | 'multi-city'
  | 'trip-fields-render'
  | 'form-changes';
type TicketFormTrackingEventType =
  | 'invoked'
  | 'resolved'
  | 'rejected'
  | 'alerted'
  | '-ok'
  | '-!ok'
  | 'status-changed-invalid';

export const initialize = () => {
  if (gaMeasurementId === undefined) {
    throw new Error(
      'Unable to resolve REACT_APP_GA_MEASUREMENT_ID key in the Environment Variables accessible to this process'
    );
  }
  ReactGA.initialize(gaMeasurementId);
};

const serialize = (object?: TrackingSerializable) => {
  if (object === undefined) {
    return undefined;
  }

  const base = {};
  if (object instanceof Response) {
    const response = object as Response;
    return {
      ...base,
      ok: response.ok,
      status: response.status,
      statusText: response.statusText,
      amzn_xrayid: AmazonXrayTraceId(response)
    };
  } else if (object instanceof Error) {
    const caught = object as Error;
    return {
      ...base,
      error: caught.message
    };
  }
  // object instanceof TrackingGenericInfoSerializable ==> inferred
  const { uid, info } = object as TrackingGenericInfoSerializable;
  return {
    ...base,
    ...(uid !== '' && { uid: uid }),
    ...(uid !== '' && info !== '' && { info: `${uid}|${info}` })
  };
};

const resolve = (object?: TrackingSerializable) => {
  if (object === undefined) {
    return undefined;
  } else if (!(object instanceof Error)) {
    return object;
  }
  // object instanceof Error ==> inferred
  throw object as Error;
};

export const trackTicketingForm = (
  category: TicketFormTrackingCategory,
  evtType: TicketFormTrackingEventType,
  object?: TrackingSerializable
) => {
  const nameRoot = `ticketingform_${category}`;
  const eventName: string = (() => {
    switch (evtType) {
      case 'invoked':
        return `${nameRoot}`;
      case 'alerted':
      case 'resolved':
      case 'rejected':
        return `${nameRoot}_${evtType}`;
      case '-ok':
        return `${nameRoot}_response_ok`;
      case '-!ok':
        return `${nameRoot}_response_not_ok`;
      case 'status-changed-invalid':
        return `${nameRoot}_status_invalid`;
    }
  })();
  // fire event and return resolved values
  ReactGA.event(eventName, serialize(object));
  return resolve(object);
};

const tripFieldsRendered: { [key: string]: { travelDate: number }[] } = {};

export const trackTripFieldRendered = (
  trackingContext: { tripId: string; renderIdx: number },
  values: any
) => {
  const { tripId, renderIdx } = trackingContext;
  if (values === undefined || values?.travelDate === undefined) {
    // TODO: could be here for a new Ticket too, so firing like this might not be the best idea; ie: it could be excessive...
    trackTicketingForm('trip-fields-render', 'rejected', {
      uid: 'travelDateUndefined',
      info: `${tripId}@${renderIdx}`
    });
    return;
  }

  if (tripFieldsRendered[tripId] === undefined) {
    // initialize tracking for this Trip ID, if it is the first time we're seeing it
    tripFieldsRendered[tripId] = [];
  }

  const existingRenderCount = tripFieldsRendered[tripId].length;
  if (renderIdx <= existingRenderCount) {
    // if (renderIdx < existingRenderCount): we are probably re-rendering this TripField, so just update values
    if (renderIdx < existingRenderCount) {
      const newTravelDate = new Date(values?.travelDate).getTime();
      if (newTravelDate === tripFieldsRendered[tripId][renderIdx].travelDate) {
        // no changes made; silently exit!
        return;
      }
    }

    // if (renderIdx === existingRenderCount): new TripField is being rendered, just add the new travelDate blindly
    tripFieldsRendered[tripId][renderIdx] = {
      travelDate: new Date(values?.travelDate).getTime()
    };
  } else {
    // TODO: should we ever land here, hmm...
    return;
  }

  const rendered = tripFieldsRendered[tripId];
  if (rendered.length >= 2) {
    // we've rendered at least two TripFields, time to compare what we've shown the user
    for (let idx = 1; idx < tripFieldsRendered[tripId].length; idx++) {
      if (rendered[idx].travelDate < rendered[idx - 1].travelDate) {
        // we found a pairing with the ticket travel dates incorrectly ordered, sad!
        trackTicketingForm('trip-fields-render', '-!ok', {
          uid: 'travelDateOrdering',
          info: `${tripId}[${rendered[idx].travelDate}@${idx},${
            rendered[idx - 1].travelDate
          }@${idx - 1}]`
        });
      }
    }
  }
};

import React, { useCallback, useEffect, useState } from 'react';
import { Field, FormikProps } from 'formik';
import TextField from './TextField';
import { useDebounce } from '../../helpers';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import FormHeader from './FormHeader';

type ContactProps = {
  updateOrder(values: any): Promise<void>;
};

const Contact: React.FC<FormikProps<any> & ContactProps> = ({
  values,
  updateOrder,
  ...props
}) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const debouncedEmail = useDebounce(values.email, 500);
  const [phone, setPhone] = useState(values.phone);
  const debouncedPhone = useDebounce(phone || '', 500);
  const [loading, setLoading] = useState(false);

  const initialLoad = useCallback(() => {
    if (!firstLoad) {
      setLoading(true);
      updateOrder({ phone: debouncedPhone, email: debouncedEmail });
      setLoading(false);
    } else {
      setFirstLoad(false);
    }
  }, [debouncedEmail, debouncedPhone, updateOrder, firstLoad]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <div className="field-group-card">
      <FormHeader title="CONTACT" loading={loading} />
      <div className="mx-6 mt-4">
        <div className="grid grid-cols-3 gap-4 w-full">
          <Field
            type="text"
            label="To"
            required
            name="email.to"
            as={TextField}
            // {...props}
          />
          <Field type="text" label="Cc" name="email.cc" as={TextField} />
          <Field type="text" label="Bcc" name="email.bcc" as={TextField} />
          <Field
            type="text"
            label="Agent Invoice Number"
            name="email.subject"
            as={TextField}
            // {...props}
          />
        </div>
        <div className="mt-2">
          <label>
            Phone Number <span className="text-red-500">*</span>
          </label>
          <IntlTelInput
            containerClassName="intl-tel-input allow-dropdown w-full "
            inputClassName={`${
              props.errors.phone ? ' border-red-400' : ''
            } border py-3 w-full rounded form-control mt-2`}
            autoComplete="on"
            value={values.phone?.number}
            defaultCountry={values.phone?.iso2}
            separateDialCode={true}
            onPhoneNumberChange={(valid, value, country) => {
              props.setFieldValue('phone.number', value);
              props.setFieldValue('phone.dialCode', country.dialCode);
              props.setFieldValue('phone.iso2', country.iso2);
              setPhone({
                dialCode: country.dialCode,
                iso2: country.iso2,
                number: value
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;

import React, { useEffect, useState } from 'react';
import { FastField as Field } from 'formik';
import TextField from './TextField';

interface TripPassengersProps {
  tripId: string;
  tripIdx: number;
  values: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TripPassengers: React.FC<TripPassengersProps> = ({
  tripId,
  tripIdx,
  values,
  setLoading,
  ...props
}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      setLoading(true);
    } else {
      setFirstLoad(false);
    }
  }, [values, firstLoad, setLoading]);

  // this component should return for THIS trip, every passenger.
  /* 
  "trips": [
        {
          "tripId": "62a3fadcd3904f6e0fdda6e7",
          "ticketNumber": "ts",
          "comment": ""
        },
        {
          "tripId": "62a3fadcd3904f6e0fdda6e8",
          "ticketNumber": "",
          "comment": ""
        }
      ]
  */

  const tripPassengers = values.reduce((c: any, p: any, i: number) => {
    const pTrip = p.trips?.find((p: any, j: number) => p.tripId === tripId);
    c.push({
      name: `${p.firstName} ${p.middleName} ${p.lastName}`,
      passengerIndex: i,
      ...pTrip
    });
    return c;
  }, []);

  return (
    <>
      <div className="bg-gray-100 px-6 py-1 text-gray-800 uppercase border-gray-200 border-b-2 border-t-2 mt-4 flex">
        <div className="flex-grow">Trip Passengers</div>
      </div>
      {tripPassengers.map((passenger: any, i: number) => (
        <div
          key={`${passenger.passengerIndex}${tripIdx}`}
          className="md:grid grid-cols-9 gap-4 px-6 pb-2 rounded-b border-gray-200 border-b-2"
        >
          <div className="flex text-lg items-center col-span-2">
            <p className="w-full mr-8 mt-8">{passenger.name}</p>
          </div>
          <div className="col-span-4">
            <Field
              type="text"
              label="Ticket number"
              required
              name={`passenger[${passenger.passengerIndex}].trips[${tripIdx}].ticketNumber`}
              as={TextField}
              pattern="(\d{3})[-.](\d{10})$"
              title="###-##########"
              placeholder="###-##########"
              // {...props}
            />
          </div>
          {/* <Field
            type="text"
            label="Comment"
            name={`passenger[${passenger.passengerIndex}].trips[${tripIdx}].comment`}
            as={TextField}
          /> */}
        </div>
      ))}
    </>
  );
};

export default TripPassengers;

import React from 'react';
import { FieldArrayRenderProps } from 'formik';

interface RedDeleteButtonProps {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

const RedDeleteButton: React.FC<RedDeleteButtonProps> = ({
  arrayHelpers,
  index,
  onClick,
  className
}) => {
  return (
    <div
      className={`flex absolute w-6 h-6 rounded-full bg-red-200 mt-2 cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div
        style={{ height: '2px', margin: '11px 0 0 4px' }}
        className="absolute w-4 bg-red-400 transform rotate-45"
      />
      <div
        style={{ height: '2px', margin: '11px 0 0 4px' }}
        className="absolute w-4 bg-red-400 transform -rotate-45"
      />
    </div>
  );
};

export default RedDeleteButton;

import React from 'react';

type Props = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

export const FieldGroupButton: React.FC<Props> = ({ children, onClick }) => {
  return (
    <div
      className="text-center text-lg p-3 w-full bg-gray-100 -mb-8 text-gray-600 font-semibold cursor-pointer"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const AmazonRequestId = (response: Response) =>
  response.headers.get('x-amzn-RequestId') || 'undefined';
export const AmazonXrayTraceId = (
  response: Response,
  targetKey: string = 'Root'
) => {
  const traceID = response.headers.get('X-Amzn-Trace-Id');
  if (!traceID) {
    return 'undefined';
  } else if (traceID.indexOf(';') === -1) {
    return traceID; // pass-through
  }
  const kvMapping = traceID.split(';').map(kvPair => kvPair.split('='));
  for (const [key, value] of kvMapping) {
    if (targetKey.toLowerCase() === key.toLowerCase()) {
      return value; // found key!
    }
  }
  return traceID; // pass-through
};
export const AmazonApiGatewayId = (response: Response) =>
  response.headers.get('x-amz-apigw-id') || 'undefined';

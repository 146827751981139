import React, { useState } from 'react';
import { FormikProps } from 'formik';
import TripFields from './TripFields';
import FlightInfo from './FlightInfo';
import TripPassengers from './TripPassengers';
import FormHeader from './FormHeader';

interface FlightProps {
  fieldGroup: string | number;
}

const Trip: React.FC<FormikProps<{
  [fieldGroup: string]: {
    _id: string;
    orderId: string;
    flightInfo: [{ arriveDatetime: string }];
    travelDate: string;
  };
}> &
  FlightProps> = ({ values, fieldGroup, setFieldValue, ...props }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="field-group-card">
      <FormHeader title={`${fieldGroup} TRIP`} loading={loading} />
      <TripFields
        fieldGroup={fieldGroup}
        values={values[fieldGroup]}
        setFieldValue={setFieldValue}
        setLoading={setLoading}
        minDate={
          (fieldGroup === 'return' &&
            values.outbound.flightInfo[values.outbound.flightInfo.length - 1]
              ?.arriveDatetime) ||
          new Date(Date.now() - 86400000).toISOString().slice(0, -8)
        }
        trackingContext={{
          tripId: values[fieldGroup]._id,
          renderIdx: fieldGroup === 'outbound' ? 0 : 1
        }}
      />
      <TripPassengers
        tripId={values[fieldGroup]._id}
        tripIdx={fieldGroup === 'outbound' ? 0 : 1}
        values={values.passenger}
        setLoading={setLoading}
        {...props}
      />
      <FlightInfo
        fieldGroup={fieldGroup}
        values={values}
        {...props}
        setFieldValue={setFieldValue}
      />
    </div>
  );
};

export default Trip;

import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { useDebounce } from '../../helpers';
import airports from '../../constants/airport_names.json';

export default function AirportField({
  placeholder,
  name,
  handleBlur,
  ariaLabel,
  parentField,
  setFieldValue,
  label,
  required,
  ...props
}: {
  name: string;
  ariaLabel: string;
  setFieldValue: any;
  parentField?: any;
  placeholder?: string;
  required?: boolean;
  label?: string;
  error?: any;
  handleBlur?: any;
}) {
  const [userInput, setUserInput] = useState('');
  const debouncedValue = useDebounce(userInput, 250);

  useEffect(() => {
    const nameSplit = name.split('.');
    const fieldName = nameSplit[nameSplit.length - 1];
    setUserInput(parentField[fieldName]);
  }, [name, parentField]);

  function handleChange(e: any) {
    setUserInput(e.target.value);
  }

  const { error } = props;
  if (!parentField) return null;
  return (
    <div className="mt-2">
      <label>
        {label} <span className="text-red-500">{required ? '*' : ''}</span>
      </label>
      <div className="flex rounded w-full">
        <Autocomplete
          {...props}
          inputProps={{
            placeholder: placeholder,
            name: name,
            onBlur: handleBlur,
            'aria-label': ariaLabel
          }}
          getItemValue={item => item}
          items={airports}
          shouldItemRender={(item, value) =>
            debouncedValue !== '' &&
            item.toLowerCase().indexOf(debouncedValue.toLowerCase()) > -1
          }
          renderItem={(item, isHighlighted) => {
            return (
              <div
                key={item}
                className={`${
                  isHighlighted ? 'bg-gray-100' : 'bg-white'
                } p-2 flex border-b `}
              >
                <div>{item}</div>
              </div>
            );
          }}
          renderMenu={(items, value, style) => {
            return (
              <div
                className="w-full absolute overflow-auto z-10 border-b border-l border-r shadow-md"
                style={{ maxHeight: '230px', zIndex: 20 }}
                children={items}
              />
            );
          }}
          renderInput={props => (
            <input
              className={`${
                error ? 'border-2 border-red-light' : 'border border-blue'
              } p-3 rounded w-full mt-2`}
              style={{ WebkitAppearance: 'none' }}
              {...props}
            />
          )}
          wrapperStyle={undefined}
          wrapperProps={{ className: 'w-full relative' }}
          value={userInput}
          onChange={e => {
            handleChange(e);
            setFieldValue(name, e.target.value);
          }}
          onSelect={val => {
            setUserInput(val);
            setFieldValue(name, val);
          }}
        />
      </div>
    </div>
  );
}

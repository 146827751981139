import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TicketingForm from './features/TicketingForm/TicketingForm';
import { AgentTicketContainer } from './features/TicketList/AgentTicketContainer';
import { QueryClient, QueryClientProvider } from 'react-query';
import Navbar from './components/Navbar';
import Homepage from './features/Homepage/Homepage';
import ETicketConfirmation from './features/ETicketConfirmation';
import CompatibilityMode from './features/TicketingForm/CompatibilityMode';
import { SupervisorTicketContainer } from './features/TicketList/SupervisorTicketContainer';
import { initialize as initializeTracking } from './helpers/tracking';

initializeTracking();
const queryClient = new QueryClient();

const App: React.FC = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Router>
      <div className="bg-gray-100 h-full">
        <Navbar />
        <div className="pt-24">
          <div className="container mx-auto">
            {isAuthenticated ? (
              <QueryClientProvider client={queryClient}>
                <Switch>
                  <Route path="/order/:id" render={() => <TicketingForm />} />
                  <Route
                    path="/compatible/:id"
                    render={() => <CompatibilityMode />}
                  />
                  <Route
                    path="/tickets"
                    render={() => <AgentTicketContainer />}
                  />
                  <Route
                    path="/supervisor"
                    render={() => <SupervisorTicketContainer />}
                  />
                  <Route
                    path="/eticket_submission_confirmation"
                    render={() => <ETicketConfirmation />}
                  />
                  <Route path="/" render={() => <Homepage />} />
                </Switch>
              </QueryClientProvider>
            ) : (
              <div>You must be signed in to see this page!</div>
            )}
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
